<template>
    <div>
        <div class="w-90 center f3 b pv4-l pv3 mini-spacing">Settings</div>
        <!-- settings -->

        <!-- menu -->
        <div class="w-90 center">
            <div class="flex scrollmenu box-border-bottom">
                <router-link active-class="active-menu" :to="{ name: 'Settings' }">
                    <div class="mr3">Profile</div>
                </router-link>

                <!-- <router-link active-class="active-menu" :to="{ name: 'Notification' }"><div class="mr3">Notification</div></router-link>-->
                <template v-if="role === 'admin' || role === 'owner'">
                    <router-link active-class="active-menu" :to="{ name: 'BusinessProfile' }">
                        <div class="mr3">Business profile</div>
                    </router-link>
                    <router-link :class="{ 'active-menu': $route.fullPath.startsWith('/settings/change-password') || $route.fullPath.startsWith('/settings/manage-account') }" active-class="active-menu" :to="{ name: 'AccountSettings' }">
                        <div class="mr3">Account settings</div>
                    </router-link>

                    <router-link v-if="!orgData.isSubBusiness && myPlan?.businessPlan !== 'Micro Business Plan'" active-class="active-menu" :to="{ name: 'BillingNew' }"
                        ><div class="mr3">Billings</div>
                    </router-link>

                    <router-link v-if="!orgData.isSubBusiness" active-class="active-menu" :to="{ name: 'Plan' }">
                        <div class="mr3">Plan</div>
                    </router-link>

                    <router-link v-if="!orgData.isSubBusiness"
                        :class="{ active_menu: $route.fullPath.startsWith('/settings/roles') }"
                        active-class="active-menu"
                        :to="{ name: 'Teams' }"
                    >
                        <div class="mr3">Teams</div>
                    </router-link>
                    <router-link
                        active-class="active-menu"
                        :class="{ 'active-menu': $route.fullPath.startsWith('/settings/archives') }"
                        :to="{ name: 'EntitiesArchive' }"
                    >
                        <div class="mr3">Archives</div>
                    </router-link>

                    <router-link 
                        active-class="active-menu" 
                        :class="{ 'active-menu': $route.fullPath.startsWith('/settings/integrations') }"
                        :to="{ name: 'Integrations' }"
                    > 
                        <div class="mr3">Integrations</div>
                    </router-link>

                    <router-link v-if="!orgData.isSubBusiness && myPlan && myPlan?.businessPlan !== 'Micro Business Plan' && businesses?.length < myPlan?.businesses"
                        active-class="active-menu" 
                        :class="{ 'active-menu': $route.fullPath.startsWith('/settings/add-business') }"
                        :to="{ name: 'AddBusiness' }"
                    > 
                        <div class="mr3">Add Business</div>
                    </router-link>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'

export default {
    name: 'SettingsHeader',

    setup() {
        const store = useStore()
        const myPlan = computed(() => store.state?.Settings?.paymentPlan)
        const businesses = computed(() => store?.state?.Settings?.businesses)
        const role = computed(() => store?.state?.Auth?.role)
        const orgData = computed(() => store?.state?.Auth?.userData)

        onMounted(() => {
            store.dispatch('Settings/getPlan')
            store.dispatch('Settings/getBusinesses')
        })

        return {
            myPlan,
            businesses,
            role,
            orgData,
        }
    }
}
</script>

<style scoped></style>
