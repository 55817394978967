<template>
    <settings-wrapper>
        <template v-slot:child>
            <form class="box-border w-60-l w-90 center mt4" @submit.prevent="handleUpdateProfile">
                <div class="box-border-bottom pa3 b f4">Edit your profile picture</div>
                <div class="w-80-l w-90 center pa3" style="background: #fbfcfe">
                    <div
                        class="flex flex-wrap justify-between items-center"
                        @drop.prevent="handleDragImage"
                        @dragover.prevent
                        @dragenter.prevent
                    >
                        <div class="w-20-l w-100 pb3 tc b f4 pb0-l pointer" @click.prevent="handleClickUpload">
                            <img
                                :src="state?.image || state?.imageUrl || require('@/assets/images/profile-pic.svg')"
                                alt="Org Image"
                                class="h-80-l w-80-l h-60 w-60 rounded-circle"
                                style="object-position: center; object-fit: cover; height: 80px; width: 80px"
                            />
                        </div>
                        <div class="box-border pa4 w-80-l w-100">
                            <span class="dn">
                                <input
                                    ref="imageElRef"
                                    type="file"
                                    name="imageFile"
                                    id="imageFile"
                                    accept="image/*"
                                    @change.prevent="handleSelectImage"
                                />
                            </span>
                            <span class="font-w1 lh-copy pointer" style="color: #132c8c" @click.prevent="handleClickUpload"
                                >Upload
                            </span>
                            or drag and drop your company logo PNG, JPG, GIF up to 10MB
                        </div>
                        <div v-if="showPrompt" class="text-danger">
                            Ready to showcase your logo? Please upload a square image, ideally sized at 200px by 200px, and make
                            sure it's under 2MB for the perfect fit.
                        </div>
                    </div>
                    <div class="flex flex-wrap flex-column-m justify-between" style="gap: 1rem">
                        <div class="flex flex-column w-100">
                            <div class="b pt4 pb2">First Name</div>
                            <input
                                type="text"
                                class="pa2 box-border"
                                name="firstName"
                                placeholder="Enter your first name"
                                id=""
                                v-model="state.firstName"
                                disabled
                            />
                        </div>

                        <div class="flex flex-column w-100">
                            <div class="b pt2 pb2">Last Name</div>
                            <input
                                type="text"
                                class="pa2 box-border"
                                name=""
                                placeholder="Enter your last name"
                                id="lastName"
                                v-model="state.lastName"
                                disabled
                            />
                        </div>
                    </div>
                    <div class="flex flex-column">
                        <div class="b pt3 pb2">Email</div>
                        <input
                            type="text"
                            class="pa2 box-border"
                            name=""
                            placeholder="Enter your email"
                            id="email"
                            v-model="state.email"
                            disabled
                        />
                    </div>
                    <div class="flex flex-column">
                        <div class="b pt3 pb2">Phone Number</div>
                        <!-- <div class="flex">
                            <select
                                type="tel"
                                class="pa2 box-border w-20"
                                name=""
                                placeholder="Enter your phone number"
                                id="phone"
                                v-model="state.countryCode"
                            >
                                <option value="+234" selected>+234</option>
                                <option v-for="country in sortedCountries" :key="country.code" :value="country.dial_code">
                                    {{ country.dial_code }}
                                </option>
                            </select>
                            <input
                                type="tel"
                                class="pa2 box-border w-80"
                                name=""
                                placeholder="Enter your phone number"
                                id="phone"
                                v-model="state.phone"
                            />
                        </div> -->
                        <vue-tel-input
                            :type="'tel'"
                            :placeholder="'Enter your phone number'"
                            class="box-border w-100"
                            :id="'phone'"
                            :name="'phone'"
                            mode="international"
                            :defaultCountry="'NG'"
                            v-model="state.phone"
                        ></vue-tel-input>
                    </div>
                    <div class="flex flex-column">
                        <div class="b pt3 pb2">Staff role</div>
                        <input
                            type="text"
                            class="pa2 box-border"
                            name=""
                            placeholder="Enter your staff role"
                            id="role"
                            disabled
                            v-model="state.role"
                        />
                    </div>
                    <div class="flex flex-column">
                        <div class="b pt3 pb2">Designation</div>
                        <input
                            type="text"
                            class="pa2 box-border"
                            name=""
                            placeholder="Creative Design"
                            id="bio"
                            v-model="state.bio"
                        />
                        <div class="f7 f6-l pt1">Brief description for your profile. URLs are hyperlinked.</div>
                    </div>
                    <div
                        class="flex flex-wrap justify-between items-center pt3 pb2"
                        @drop.prevent="handleDragSignature"
                        @dragover.prevent
                        @dragenter.prevent
                    >
                        <div class="w-30-l w-100 pb3 tc b f4 pb0-l">
                            <img
                                :src="state?.signature || state?.signatureUrl || require('@/assets/images/signature.png')"
                                alt="my signature"
                                class="h-80-l w-80-l h-60 w-60"
                                style="object-position: center; object-fit: cover; height: 80px"
                            />
                        </div>
                        <div class="box-border pa4 w-70-l w-100">
                            <span class="dn">
                                <input
                                    ref="signatureElRef"
                                    type="file"
                                    name="signatureFile"
                                    id="signatureFile"
                                    accept="image/*"
                                    @change.prevent="handleSelectSignature"
                                />
                            </span>
                            <span
                                class="font-w1 lh-copy pointer"
                                style="color: #132c8c"
                                @click.prevent="handleSignatureClickUpload"
                                >Upload
                            </span>
                            or drag and drop your signature PNG, JPG, GIF up to 10MB
                        </div>
                    </div>
                </div>
                <div class="pv4 ph3" style="border-top: 1px solid #e3e8ee">
                    <div class="flex justify-between">
                        <div class="db-l dn"></div>
                        <div class="flex">
                            <!-- <button class="btn2">Cancel</button> -->
                            <button class="ml3" :disabled="state.disableButton">
                                {{ state.disableButton ? 'Updating...' : 'Update Profile' }}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
            <div class="box-border w-60-l w-90 center mt4">
                <div class="box-border-bottom pa3 b f4" style="background: white">Change Password</div>
                <form class="pa3" style="background: #fbfcfe" @submit.prevent="handleSubmit">
                    <div class="flex flex-column">
                        <div class="b pt4 pb2">Old Password</div>
                        <input
                            type="password"
                            class="pa2 box-border"
                            name=""
                            placeholder="Old password"
                            id="old-password"
                            v-model="state.oldPassword"
                        />
                    </div>
                    <div class="flex flex-column">
                        <div class="b pt3 pb2">New Password</div>
                        <input
                            type="password"
                            class="pa2 box-border"
                            name=""
                            placeholder="New password"
                            id="new-password"
                            v-model="state.newPassword"
                        />
                    </div>
                    <div class="flex flex-column">
                        <div class="b pt3 pb2">Confirm New Password</div>
                        <input
                            type="password"
                            class="pa2 box-border"
                            name=""
                            placeholder="Confirm new password"
                            id="confirm-new-password"
                            v-model="state.confirmNewPassword"
                        />
                        <span
                            v-if="
                                state.newPassword !== '' &&
                                state.confirmNewPassword !== '' &&
                                state.newPassword !== state.confirmNewPassword
                            "
                            class="text-danger"
                            >New password does not match</span
                        >
                    </div>
                    <div class="pa4 mt3" style="border-top: 1px solid #e3e8ee">
                        <div class="flex justify-between">
                            <div></div>
                            <div class="flex">
                                <button
                                    type="submit"
                                    class="ml3"
                                    :disabled="
                                        state.isProcesseing ||
                                        state.confirmNewPassword === '' ||
                                        state.newPassword !== state.confirmNewPassword
                                    "
                                >
                                    {{ state?.isProcesseing ? 'Processing...' : 'Submit' }}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </template>
    </settings-wrapper>
</template>

<script>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'
import SettingsWrapper from '@/views/Settings/widgets/SettingsWrapper'
import { VueTelInput } from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'

export default {
    name: 'Settings',
    components: { SettingsWrapper, VueTelInput },

    setup() {
        const store = useStore()
        const imageElRef = ref(null)
        const signatureElRef = ref(null)
        const profile = computed(() => store.state?.Settings?.profile)
        const rolePermissions = computed(() => store?.state?.Auth?.rolePermissions)
        const showPrompt = ref(false)

        const initialState = () => ({
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            role: '',
            bio: '',
            imageUrl: '',
            image: '',
            imageFile: '',
            signatureUrl: '',
            signature: '',
            signatureFile: '',
            disableButton: false,
            oldPassword: '',
            newPassword: '',
            confirmNewPassword: '',
            isProcesseing: false,
        })
        let state
        if (!profile.value) {
            state = reactive(initialState())
        } else {
            state = reactive(profile.value)
        }

        watch(
            () => profile?.value,
            (prev, next) => {
                if (prev !== next) {
                    Object.assign(state, reactive({ ...profile?.value }))
                }
            }
        )

        const handleUpdateProfile = () => {
            state.disableButton = true

            const formData = new FormData()

            const { oldPassword, newPassword, confirmNewPassword, disableButton, isProcesseing, ...rest } = state

            const data = Object.keys(rest)

            data.forEach((key) => {
                if (state[key]) formData.append(key, state[key])
            })

            store.dispatch('Settings/updateProfile', formData).then((resp) => {
                if (resp?.success) {
                    state.disableButton = false
                } else {
                    state.disableButton = false
                }
            })
        }

        const handleClickUpload = () => {
            // eslint-disable-next-line
            imageElRef?.value?.click()
            // imageElRef?.value.click('click')
        }

        const handleSelectImage = (e) => {
            if (e?.target?.files?.length) {
                const file = e.target.files[0]
                // eslint-disable-next-line prefer-destructuring
                state.imageFile = e.target.files[0]
                const reader = new FileReader()
                reader.onload = (e) => {
                    const img = new Image()
                    img.src = e.target.result
                    img.onload = () => {
                        // this checks if the image is square (200px by 200px)
                        if (img.width === 200 && img.height === 200) {
                            showPrompt.value = false
                        } else {
                            showPrompt.value = true
                            setTimeout(() => {
                                showPrompt.value = false
                            }, 10000)
                        }
                        state.imageFile = file
                        state.imageUrl = e.target.result

                        if (reader.readyState === 2) {
                            showPrompt.value = true
                            state.image = reader.result.toString()
                        }
                    }
                }
                reader.readAsDataURL(file)
            }
        }

        const handleDragImage = (e) => {
            if (e?.dataTransfer?.files?.length) {
                const file = e.dataTransfer.files[0]
                // eslint-disable-next-line prefer-destructuring
                state.imageFile = e.dataTransfer.files[0]
                const reader = new FileReader()
                reader.onload = (e) => {
                    state.imageFile = file
                    state.imageUrl = e.target.result

                    if (reader.readyState === 2) {
                        state.image = reader.result.toString()
                    }
                }
                reader.readAsDataURL(file)
            }
        }

        const handleSignatureClickUpload = () => {
            // eslint-disable-next-line
            signatureElRef?.value?.click()
        }

        const handleSelectSignature = (e) => {
            if (e?.target?.files?.length) {
                const file = e.target.files[0]
                // eslint-disable-next-line prefer-destructuring
                state.signatureFile = e.target.files[0]
                const reader = new FileReader()
                reader.onload = (e) => {
                    state.signatureFile = file
                    state.signatureUrl = e.target.result

                    if (reader.readyState === 2) {
                        state.signature = reader.result.toString()
                    }
                }
                reader.readAsDataURL(file)
            }
        }

        const handleDragSignature = (e) => {
            if (e?.dataTransfer?.files?.length) {
                const file = e.dataTransfer.files[0]
                // eslint-disable-next-line prefer-destructuring
                state.signatureFile = e.dataTransfer.files[0]
                const reader = new FileReader()
                reader.onload = (e) => {
                    state.signatureFile = file
                    state.signatureUrl = e.target.result

                    if (reader.readyState === 2) {
                        state.signature = reader.result.toString()
                    }
                }
                reader.readAsDataURL(file)
            }
        }

        const handleSubmit = () => {
            state.isProcesseing = false
            store
                .dispatch('Settings/changePassword', {
                    oldPassword: state.oldPassword,
                    newPassword: state.newPassword,
                })
                .finally(() => {
                    state.oldPassword = ''
                    state.newPassword = ''
                    state.confirmNewPassword = ''
                    state.isProcesseing = false
                })
        }

        onMounted(() => store.dispatch('Settings/getProfile'))
        return {
            state,
            handleClickUpload,
            handleDragImage,
            handleUpdateProfile,
            handleSelectImage,
            imageElRef,
            handleSignatureClickUpload,
            handleSelectSignature,
            handleDragSignature,
            signatureElRef,
            rolePermissions,
            handleSubmit,
            showPrompt,
        }
    },
}
</script>

<style scoped></style>
